export class ConfigCliente {
  static readonly APP_VERSAO = '2.77'; //atualizacao pré-cadastro

  public static APP_MAIN() {
    let url = 'http://localhost:8080/arenarh/';
    let origem = window.location.hostname.split('.')[0].replace('rh', '');
    if (origem == '') origem = 'eqs'; //adicionado para atender a eqs que é http://rh.arenago.net
    console.log(origem);
    if (origem != 'localhost') {
      url = `https://${origem}.arenanet.com.br/arenarh/`;
    }
    return url;
  }

  public static APP_REST() {
    let url = 'http://localhost:8080/arenarh/rest/';
    let origem = window.location.hostname.split('.')[0].replace('rh', '');
    if (origem == '') origem = 'eqs'; //adicionado para atender a eqs que é http://rh.arenago.net
    console.log(origem);
    if (origem != 'localhost') {
      url = `https://${origem}.arenanet.com.br/arenarh/rest/`;
    }
    return url;
  }

  //TESTE
  // static readonly APP_REST = 'http://localhost:8080/arenarh/rest/';
  // static readonly APP_MAIN = 'http://localhost:8080/arenarh/';

  //EQS
  // static readonly APP_REST = 'https://eqs.arenanet.com.br/arenarh/rest/';
  // static readonly APP_MAIN = 'https://eqs.arenanet.com.br/arenarh/';

  //ng build --output-path G:/ARENANET/ARENANET/_eclipse_projetos_demoiselle_new/arenago/src/main/webapp/dist --prod

  //TESTE
  //ng build --output-path G:/eqs-rh-teste
  //$ cd /hd2/www
  //$ mv eqs-rh-teste/ _eqs-rh-teste/
  //$ mv /home/arena/temp/eqs-rh-teste/ /hd2/www/eqs-rh-teste/
  //https://rhteste.arenago.net/

  //EQS
  //-> www-arena: www ->  eqs-rh
  //ng build --output-path G:/eqs-rh
  // mv eqs-rh/ eqs-rh-bkp/
  //mv /home/arena/temp/eqs-rh/ /hd2/www/
  // mv /home/eqs.admin/temp/eqs-rh/ /hd2/www/
}
